import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";

import "./Clients.css";


function Clients(props) {
    const { data, clients } = props;
    return (
        <div className="py-8 md:py-16 m-auto px-4 md:px-0 container-cs">
            <div className='pb-2'>
                <h2 className='w-full text-center' >
                    {data}
                </h2>
                <div className='spacer-yellow m-auto'><div><div style={{ height: "3px" }}></div></div></div>
            </div>
            <div className='py-12'>
                <div className='flex flex-wrap'>
                    {(clients && clients.length > 0) && clients.map((client, i) => (
                        <div className='md:w-1/5 w-1/3 pb-4 px-4'>
                            <GatsbyImage
                                image={client.image.localFile.childImageSharp.gatsbyImageData}
                                alt={client.image.altText}
                                className="m-auto"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Clients;
