import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"

import Layout from '../components/layout'
import Introduction from "../components/Home/Introduction"
import ServicesSection from "../components/Home/ServicesSection"
import PromoSection from "../components/Home/PromoSection"
// import PromoSection2 from "../components/Home/PromoSection2"
import Programs from "../components/Home/Programs"
import CaseStudies from "../components/Home/CaseStudies"
import ContactSection from "../components/Home/ContactSection"
import Clients from "../components/Home/Clients"



function HomeTemplate(props) {
    console.log(props)
    let data = props.data;


    const [y, setY] = useState();
    const [espaV, setEspaV] = useState(true);

    const handleNavigation = (e) => {
        const window = e.currentTarget;
        if (0 === window.scrollY) {
            setEspaV(true)
        } else {
            setEspaV(false)
        }
        setY(window.scrollY);
    };

    useEffect(() => {
        setY(window.scrollY)
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", (e) => handleNavigation(e));

        return () => { // return a cleanup function to unregister our function since its gonna run multiple times
            window.removeEventListener("scroll", (e) => handleNavigation(e));
        };
    }, [y]);



    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://trustdev.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://trustdev.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }


    return (
        <Layout
            headerMenu={props.pageContext.headerMenu}
            footerMenu={props.pageContext.footerMenu}
            currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
            availableVersions={props.pageContext.availablePages}
            globalInfo={data.wpTemplate.globalInformation}
            locale={props.pageContext.language}
            metaData={constructMetaData(data.wpPage, props.pageContext.currentPage, props.pageContext.language)}
        // services={props.pageContext.servicesCategories}
        >
            <div>
                <section id="firstSection" className="relative">
                    <Introduction
                        data={data.wpPage.homePageAcf?.introSection}
                    />
                </section>
                <section className="relative" style={{ backgroundColor: "#f3f8fe" }}>
                    <PromoSection
                        data={data.wpPage.homePageAcf?.aboutSection}
                    />
                </section>
                <section className="relative">
                    <ServicesSection
                        data={data.wpPage.homePageAcf?.servicesSection}
                        services={data.allWpService.edges}
                    />
                </section>

                <section style={{ backgroundColor: "#f3f8fe" }}>
                    <CaseStudies
                        data={data.wpPage.homePageAcf?.portfolio}
                        caseStudies={data.allWpCaseStudy.edges}
                    />
                </section>

                <section>
                    <Programs
                        data={data.wpPage.homePageAcf?.blog}
                        programs={data.allWpPost.nodes}
                        language={props.pageContext.language}
                    />
                </section>

                <section>
                    <Clients
                        data={data.wpPage.homePageAcf?.clientsTitle}
                        clients={data.wpPage.homePageAcf?.clients}
                    />
                </section>

                <section className="relative" style={{ backgroundColor: "#f3f8fe" }}>
                    <ContactSection
                        data={data.wpTemplate.globalInformation?.contactForm}
                        contactInfo={data.wpTemplate.globalInformation?.contactInfo}
                    />
                </section>

                <a href="#" target="_blank" rel="noopener" className="espa-container w-full" style={espaV ? { opacity: "1" } : { opacity: "0", zIndex: "-1" }}>
                    <img src="https://admin.trustdev.gr/wp-content/uploads/2023/02/Banner_Psifiakos-Metasx_2.png" alt="nextgeneration eu" />
                </a>

            </div>
        </Layout>
    )
}

export default HomeTemplate

export const pageQuery = graphql`query GET_PAGE($id: String, $templateId: String, $language: WpLanguageCodeEnum) {
    wpPage(id: { eq: $id}){
        seo {
            canonical
            title
            metaDesc
            opengraphDescription
            opengraphTitle
            opengraphImage {
                sourceUrl
            }
        }
        title
        homePageAcf {
            introSection{
                title
                title2
                title3
                subtitle
                shortDescription
                mainImage {
                    sourceUrl
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH, quality: 100)
                        }
                    }
                }
                mainMobileImage{
                    sourceUrl
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH, quality: 100)
                        }
                    }
                }
                button {
                    label
                    url
                }
            }

            servicesSection {
                title
                subtitle
                cta {
                    label
                    link
                }
            }
            aboutSection {
                title
                subtitle
                content
                image {
                    altText
                    sourceUrl
                    localFile {
                        childImageSharp {
                            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
                        }
                    }
                }
                cta {
                    label
                    link
                }
            }
            portfolio {
                title
                subtitle
                cta{
                    label
                    link
                }
            }

            blog {
                title
                subtitle
            }

            clientsTitle
            clients {
                image {
                    altText
                    sourceUrl
                    localFile {
                        childImageSharp {
                            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
                        }
                    }
                }
            }

        }
    }

    wpTemplate(slug: {eq: $templateId}) {
		globalInformation {
            contactInfo {
                phone {
                    label
                    text
                }
                address {
                    label
                    text
                }
                email {
                    label
                    text
                }
                workingHours {
                    label
                    text
                }
            }
            contactForm{
                title
                content
                socialLinks{
                    social{
                        type
                        link
                    }
                }
                form{
                    label
                    type
                    errorMessage
                    value
                }
                formCta
            }
            footerExtra {
                description
                copyright
            }
        }
    }

    allWpCaseStudy(
        filter: {language: {code: {eq: $language}}},
        sort: { fields: [date] , order: DESC },
    ){
        edges{
            node{
                title
                uri
                featuredImage {
                    node{
                        altText
                        sourceUrl
                        localFile {
                            childImageSharp {
                                gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
                            }
                        }
                    }
                }
            }
        }
    }

    allWpService(
        filter: {language: {code: {eq: $language}}},
        sort: { fields: [date] , order: DESC }
      ){
        edges{
            node{
                title
                excerpt
                uri
                featuredImage {
                    node{
                        altText
                        sourceUrl
                        localFile {
                            childImageSharp {
                                gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
                            }
                        }
                    }
                }
                serviceAcf{
                    serviceIcon{
                        sourceUrl
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(quality: 100, placeholder: NONE, width: 70, layout: FIXED)
                            }
                        }
                        
                    }
                }
            }
        }
    }

    allWpPost(
        limit: 6,
        sort: { fields: [date] , order: DESC },
        filter: {language: {code: {eq: $language}}}
      ) {
        nodes {
            uri
            slug
            title
            date
            excerpt

            featuredImage{
                node{
                    sourceUrl
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
                        }
                    }
                }
            }

            tags{
                nodes{
                    name
                }
            }
            
        }
    }
}
`
