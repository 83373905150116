import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"

import "./PromoSection.css";


function PromoSection(props) {
    const data = props.data;
    console.log(data)
    return (
        <div className="py-16 md:py-32 m-auto text-center px-4 md:px-0 container-cs">
            <div className='flex flex-wrap'>
                <div className='w-full md:w-1/2 px-0 md:px-2 py-8'>
                    <GatsbyImage
                        alt={data.image.altText}
                        image={data.image.localFile.childImageSharp.gatsbyImageData}
                        formats={["auto", "webp", "avif"]}
                        className={"w-full"}
                    />
                </div>
                <div className='w-full md:w-1/2 ss-container flex items-center justify-start px-4 md:px-8'>
                    <div className='flex flex-wrap'>
                        <div className='ss-subtitle py-2'>
                            <span>{data.subtitle}</span>
                        </div>
                        <div className='pb-2'>
                            <h2 className='w-full text-left' >
                                {data.title}
                            </h2>
                            <div className='spacer-yellow'><div><div style={{ height: "3px" }}></div></div></div>
                        </div>

                        <div className='ss-content' dangerouslySetInnerHTML={{ __html: data.content }} />
                        <div className='flex pt-8'>
                            <a className='home-intro-bttn' href={data.cta.link}>
                                {data.cta.label}
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default PromoSection;
