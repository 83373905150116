import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"
import { format } from 'date-fns'
import { el, enGB } from 'date-fns/locale'
import Calendar from "./../../images/calendar.svg"
import "./Programs.css";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Programs(props) {
    const { data, programs, language } = props;

    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          }
        ]
      };

    return (
        <div className="py-16 md:py-32 m-auto px-4 md:px-0 container-cs">
            <div className="w-full px-4 pb-8 block text-center" >
                <div className='ss-subtitle py-2' style={{ float: "inherit" }}>
                    <span>{data.subtitle}</span>
                </div>
                <div className='pb-2'>
                    <h2 className='w-full text-center' >
                        {data.title}
                    </h2>
                    <div className='spacer-yellow m-auto'><div><div style={{ height: "3px" }}></div></div></div>
                </div>

            </div>
            <div className='pb-12'>
                <div>
                    <Slider {...settings}>
                        {(programs && programs.length > 0) && programs.map((post, i) => (
                            <article key={`program-post-${i}`} className='w-full md:w-1/3 px-4'>
                                <a href={`${post.uri}`}>
                                    <div className='prog-wrapper'>
                                        <GatsbyImage
                                            image={post.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                                            alt={post.featuredImage.node.altText}
                                            className="m-auto sc-m-img0"
                                        />
                                        <div className="blog-con-wrapper">
                                            <div>
                                                {(post?.tags && post.tags?.nodes?.length > 0) && post.tags.nodes.map((tag, j) => (
                                                    <React.Fragment>
                                                        <span className='program-tag' key={`program-tag-${j}`}>{tag.name}</span>
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                            <h3 className="pt-4">{post.title}</h3>

                                            <div className="w-full z-10">
                                                <div className="flex  blog-info pt-2">
                                                    <div className="flex justify-center">
                                                        <div>
                                                            <img src={Calendar} style={{ width: "15px", marginRight: "5px", paddingTop: "2px" }} />
                                                        </div>
                                                        <div className="blog-date">{language === "EL" ? format(new Date(post.date), 'dd MMMM, yyyy', { locale: el }) : format(new Date(post.date), 'dd MMMM, yyyy', { locale: enGB })}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </article>
                        ))}
                    </Slider>

                </div>
            </div>
        </div>
    );
}

export default Programs;
