import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";

import { Link, graphql } from "gatsby"

import Bluebg from "../../images/blue-bg2.jpg";


import "./Introduction.css";

function Introduction({ data }) {
    console.log(data)
    return (

        <div className='relative'>
            <div className='block'>
                <div style={{ display: "grid" }}>
                    <div
                        style={{
                            // By using the same grid area for both, they are stacked on top of each other
                            gridArea: "1/1",
                            position: "relative",
                            // This centers the other elements inside the hero component
                            placeItems: "center",
                            display: "grid",
                        }}
                    >
                        {/* Any content here will be centered in the component */}
                        <div className="flex flex-wrap container-cs intro-home-wrapper ">
                            <div className="p-4 w-full sm:w-1/2 container">
                                <div className="px-0 text-left">
                                    <p className="home-main-p py-4 m-auto">{data.subtitle}</p>
                                    <h1 className="home-h1 underline">{data.title}<br/>{data.title2}<br/>{data.title3}</h1>
                                    <div className='w-full block md:hidden pb-4' style={{ maxWidth: "455px" }}>
                                    <GatsbyImage
                                        alt={data.mainImage.altText}
                                        loading={"eager"}
                                        image={data.mainImage.localFile.childImageSharp.gatsbyImageData}
                                        formats={["auto", "webp", "avif"]}
                                        // imgStyle={{ objectFit: 'contain' }}
                                        className={"w-full"}
                                    />
                                </div>
                                    <div className='intro-sd pt-4 md:pt-0' dangerouslySetInnerHTML={{ __html: data.shortDescription }} />
                                    <div className='mt-8 pb-8'>
                                        <Link to={"/" + data.button.url + "/"} className="home-intro-bttn">{data.button.label}</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="p-4 w-full sm:w-1/2 hidden md:flex justify-end pb-12">
                                {/* <div className='light-blue-blob hidden md:block'></div> */}
                                <div className='w-full' style={{ maxWidth: "455px" }}>
                                    <GatsbyImage
                                        alt={data.mainImage.altText}
                                        loading={"eager"}
                                        image={data.mainImage.localFile.childImageSharp.gatsbyImageData}
                                        formats={["auto", "webp", "avif"]}
                                        // imgStyle={{ objectFit: 'contain' }}
                                        className={"w-full"}
                                    />
                                </div>
                                <div className='relative'>
                                    <div className='bbg-c'>
                                        <img src={Bluebg} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Introduction;
